import { Document, Packer, Paragraph, TextRun, AlignmentType } from 'docx';
const apiUrl = process.env.REACT_APP_API_URL;
const structureData = (data) => {
    let categoriesMap = {};

    data.forEach(item => {
        const { category, subcategory } = item.question_polycap;
        if (!categoriesMap[category]) {
            categoriesMap[category] = {};
        }
        if (!categoriesMap[category][subcategory]) {
            categoriesMap[category][subcategory] = [];
        }
        categoriesMap[category][subcategory].push(item);
    });

    return categoriesMap;
};
export const exportWord = async (beneficiary) => {
        let children = [];
    try {
        const response = await fetch(apiUrl+`answers/${beneficiary.id}`);
        let data = await response.json();
        data = data.sort((a, b) => a.question_polycap.order - b.question_polycap.order);
        console.log('les datas',data);
        const categorizedQuestions = structureData(data);
        Object.entries(categorizedQuestions).forEach(([category, subcategories]) => {
            // Ajouter la catégorie au document
            // Ajouter les sous-catégories et les questions au document
        });

        // Informations générales
        const generalInfo = [
            `Nom de l'enfant : ${beneficiary.name}`,
            `Prénom de l'enfant : ${beneficiary.firstName}`,
            `Date de naissance : ${beneficiary.birth_date}`,
            `Sexe : ${beneficiary.sex}`,
            `Établissement scolaire : ${beneficiary.school.name}`,
            `Diagnostic(s) : ${beneficiary.illness}`,
            `Caractéristique des déficiences : ${beneficiary.illness}`, // Utilisez une description plus appropriée si disponible
            `Epileptique : ...`, // Completez avec les informations disponibles
            `Thérapie : ${beneficiary.therapies}`,
            `Lieu de vie : ${beneficiary.lieudevie}`
        ];
        children.push(new Paragraph({
            children: [
                new TextRun({
                    text: "Informations Générales",
                    bold: true,
                    size: 32,
                })
            ],
            spacing: {after: 200},
            alignment: AlignmentType.CENTER,
        }));
        const createInfoParagraph = (boldText, normalText) => {
            return new Paragraph({
                children: [
                    new TextRun({
                        text: boldText,
                        bold: true,
                    }),
                    new TextRun(" "),
                    new TextRun(normalText),
                ],
                spacing: {after: 200},
            });
        };

        // Ajout des informations générales avec mise en forme spécifique
        children.push(createInfoParagraph("Nom de l'enfant :", beneficiary.last_name));
        children.push(createInfoParagraph("Prénom de l'enfant :", beneficiary.first_name));
        children.push(createInfoParagraph("Date de naissance :", beneficiary.birth_date.split(' ')[0]));
        children.push(createInfoParagraph("Sexe :", beneficiary.sex));
        children.push(createInfoParagraph("Établissement scolaire :", beneficiary.school));
        children.push(createInfoParagraph("Diagnostic(s) :", beneficiary.illnesses));
        // children.push(createInfoParagraph("Caractéristique des déficiences :", beneficiary.deficiencyCharacteristics)); // Utilisez une description plus appropriée si disponible
        // Complétez avec d'autres informations de la même manière
        if (categorizedQuestions && Object.keys(categorizedQuestions).length > 0) {
            // Génération des paragraphes pour chaque catégorie et ses sous-catégories
            Object.entries(categorizedQuestions).forEach(([category, subcategories]) => {
                children.push(new Paragraph({
                    children: [new TextRun({
                        text: category,
                        font: "Times New Roman Bold",
                        bold: true,
                        size: 32,
                    })],
                    alignment: AlignmentType.CENTER,
                    spacing: {after: 200},
                }));

                Object.entries(subcategories).forEach(([subcategory, questions]) => {
                    children.push(new Paragraph({
                        children: [new TextRun({
                            text: subcategory,
                            font: "Times New Roman Bold",
                            underline: true,
                            bold: true,
                            size: 22,
                        })],
                        spacing: {after: 100},
                    }));

                    questions.forEach((question, index) => {
                        children.push(new Paragraph({
                            children: [
                                new TextRun({
                                    text: `${index + 1}. `,
                                    bold: true, // Mettez cette partie en gras
                                    size: 20,
                                }),
                                new TextRun({
                                    text: `${question.question_polycap.question} - `,
                                    bold: true, // Si vous voulez aussi cette partie en gras
                                    size: 20,
                                }),
                                new TextRun({
                                    text: `${question.answer}`,
                                    size: 20,
                                })
                            ],
                            spacing: {after: 100},
                        }));
                    });
                });
            });
        }

        // Création du document avec les sections générées
        const doc = new Document({
            sections: [{
                properties: {},
                children: children,
            }],
        });

        // Pack and save the document
        Packer.toBlob(doc).then(blob => {
            // Download the document
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = beneficiary.first_name+" "+beneficiary.last_name+".docx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }
    catch (error) {
        console.log(beneficiary);
        console.error('Error fetching answers:', error);
    }
};