import React, { useEffect, useState } from 'react';
import './formulaire.scss';
import Img from './assets/img/wheelchair.png';
import Stepper from "./Stepper";
import QuestionCard from "./QuestionCard";
import { useParams } from "react-router-dom";
import Layout from "./Layout";

const Formulaire = () => {
    let { beneficiaryId } = useParams();
    const [questionsData, setQuestionsData] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentAnswer, setCurrentAnswer] = useState(""); // Pour stocker la réponse actuelle
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch(apiUrl+'questions_polycap');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                let  data = await response.json();
                data = data.sort((a, b) => a.order - b.order);
                setQuestionsData(data);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };
        fetchQuestions();
    }, []);

    const onCategorySelect = async (category) => {
        const newIndex = questionsData.findIndex(question => question.category === category);
        if (newIndex !== -1) {
           // setCurrentQuestionIndex(newIndex);
            await saveCurrentAnswer(currentAnswer);
            await handleNavigation(newIndex);
        }
    };

    const onSubCategorySelect = async (subCategory) => {
        const newIndex = questionsData.findIndex(question => question.subcategory === subCategory);
        if (newIndex !== -1) {
            await saveCurrentAnswer(currentAnswer);
            await handleNavigation(newIndex);
            //setCurrentQuestionIndex(newIndex);
        }
    };

    function saveCurrentAnswer(updatedAnswer) {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    answer: updatedAnswer
                })
            };
            let url = apiUrl+'questions/' + questionsData[currentQuestionIndex].id+'/'+ beneficiaryId;

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("Mise à jour réussie:");
                    resolve(data);
                })
                .catch(error => {
                    console.error("Erreur lors de la mise à jour:", error);
                    reject(error);
                });
        });
    }

    const handleNavigation = async (newIndex) => {
        // if (await saveCurrentAnswer()) {
        //     setCurrentQuestionIndex(newIndex);
        // }
        setCurrentQuestionIndex(newIndex);
    };

    const handleNextQuestion = () => {
        console.log('avant',currentQuestionIndex);
        const newIndex = currentQuestionIndex + 1 < questionsData.length ? currentQuestionIndex + 1 : currentQuestionIndex;
        console.log('apres',newIndex);
        handleNavigation(newIndex);
    };

    const handlePreviousQuestion = () => {
        const newIndex = currentQuestionIndex > 0 ? currentQuestionIndex - 1 : 0;
        handleNavigation(newIndex);
    };

    // Élimine les doublons dans les catégories
    let categories = [...new Set(questionsData.map(item => item.category))];
    const categoriesWithSubcategories = categories.map(category => ({
        category,
        subCategories: [...new Set(questionsData.filter(item => item.category === category).map(item => item.subcategory))]
    }));

    return (
        <Layout>
        <div className="formulaire">
            <div className="formulaire-title">
                <h6 className="text-5xl text-white text-center">Questionnaire d'évaluation Poly'Cap</h6>
            </div>
            <div className="formulaire-main w-screen">
                <Stepper categoriesWithSubcategories={categoriesWithSubcategories} currentQuestion={questionsData[currentQuestionIndex]}
                         onCategorySelect={onCategorySelect}
                         onSubCategorySelect={onSubCategorySelect}/>
                {currentQuestionIndex < questionsData.length && (
                    <QuestionCard
                        item={questionsData[currentQuestionIndex]}
                        questionId={questionsData[currentQuestionIndex].id}
                        beneficiaryId={beneficiaryId}
                        onBack={handlePreviousQuestion}
                        onContinue={handleNextQuestion}
                        setCurrentAnswer={setCurrentAnswer}
                        onSaveAnswer={saveCurrentAnswer}// Passer setCurrentAnswer à QuestionCard
                    />
                )}
            </div>
        </div>
        </Layout>
    );
};

export default Formulaire;
