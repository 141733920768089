import React, {useEffect, useState} from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import {useNavigate} from "react-router-dom";
import UpdateBeneficiaryModal from "./UpdateBeneficiaryModal";
import DescriptionIcon from '@mui/icons-material/Description';
import Button from "@mui/material/Button";
import { exportWord } from './exportUtils';
function preventDefault(event) {
    event.preventDefault();
}

export default function Orders() {
    const [beneficiaries, setBeneficiaries] = useState([]);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const handleRowClick = (beneficiaryId) => {

        navigate(`/formulaire/${beneficiaryId}`);
    };
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const handleRowDoubleClick = (beneficiary) => {
        setSelectedBeneficiary(beneficiary); // Stocker le bénéficiaire sélectionné
        setModalOpen(true); // Ouvrir la modal
    };

    const fetchBeneficiaries = async () => {
        // Récupérer le token JWT stocké
        const token = localStorage.getItem('authToken');
        console.log('Le TOKEN', token);
        try {
            const response = await fetch(apiUrl+'beneficiaries', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            // Calcul de l'âge des bénéficiaires et mise à jour des données
            const updatedData = data.map(beneficiary => {
                const birthday = new Date(beneficiary.birth_date);
                const ageDifMs = Date.now() - birthday.getTime();
                const ageDate = new Date(ageDifMs); // miliseconds from epoch
                return {
                    ...beneficiary,
                    age: Math.abs(ageDate.getUTCFullYear() - 1970) // Calcul de l'âge
                };
            });
            setBeneficiaries(updatedData); // Mise à jour de l'état avec les données modifiées
        } catch (error) {
            console.error('Error fetching beneficiaries:', error);
        }
    };
    useEffect(() => {


        fetchBeneficiaries();
        console.log(beneficiaries);
    }, []);

    const handleClose = () => {
        setModalOpen(false); // Fermer la modal
        fetchBeneficiaries();
    };

    if (!beneficiaries.length) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            <Title>Questionnaires récents</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell>Etablissement</TableCell>
                        <TableCell>Age</TableCell>
                        <TableCell align="right">Complété</TableCell>
                        <TableCell>Export</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {beneficiaries.map((beneficiary) => (

                        <TableRow key={beneficiary.id}
                                  // hover
                                  // onClick={() => handleRowClick(beneficiary.id)} // Ajoutez le gestionnaire d'événements onClick
                                  // style={{cursor: 'pointer'}}
                                  onDoubleClick={() => handleRowDoubleClick(beneficiary)}
                        >
                            {/* Adaptez ces champs selon la structure de vos données */}

                            <TableCell>{`${beneficiary.first_name} ${beneficiary.last_name}`}</TableCell>
                            <TableCell>{`${beneficiary.school}`}</TableCell>
                            <TableCell>{beneficiary.age}</TableCell>
                            <TableCell align="right">
                                <Link   onClick={() => handleRowClick(beneficiary.id)} style={{cursor: 'pointer'}}>
                                    Voir questionnaire
                                </Link>
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => exportWord(beneficiary)}>
                                    <DescriptionIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {selectedBeneficiary && (
                    <UpdateBeneficiaryModal
                        open={modalOpen}
                        onClose={handleClose}
                        beneficiary={selectedBeneficiary}
                    />
                )}
            </Table>
            <Link color="primary" href="#" onClick={preventDefault} sx={{mt: 3}}>
                Voir tous les questionnaires
            </Link>
        </React.Fragment>
    );
}
