import React, {useState,useEffect} from 'react';
import './questionCard.scss'; // Assurez-vous que le chemin d'accès est correct
import Img from './assets/img/wheelchair.png';
import {CircularProgress} from "@mui/material";


const QuestionCard = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isFlipped, setIsFlipped] = useState(false);
    // Définir un état pour contrôler la visibilité de l'élément
    const [visible, setVisible] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [answer, setAnswer] = useState('');
    const handleAnswerChange = (event) => {
        setAnswer(event.target.value);
        props.setCurrentAnswer(event.target.value);
    };

    const handleSave = async (callback) => {
        setIsLoading(true);
        try {
            await props.onSaveAnswer(answer); // Supposons que cette fonction envoie la réponse au backend
            console.log("Réponse mise à jour avec succèss");
            if (callback && typeof callback === 'function') {
                console.log('ma boulangerie');
                callback(); // Exécutez la fonction de rappel pour la navigation après la sauvegarde
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la réponse :", error);
        } finally {
            setIsLoading(false);
        }
    };


    function loadJsonData(filePath) {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            fetch(filePath)
                .then(response => {
                    if (response.ok) {
                        setIsLoading(false);
                        return response.json();
                    } else {
                        setIsLoading(false);
                        throw new Error('Failed to load file: ' + filePath);
                    }
                })
                .then(data => resolve(data))
                .catch(error => reject(error));
        });
    }
    useEffect(() => {
        console.log('update en cours', props.questionId, props.beneficiaryId);
        loadJsonData(`${apiUrl}questions/${props.questionId}/${props.beneficiaryId}`) // Utilisez des template strings pour une meilleure lisibilité
            .then(jsonData => {
                setData(jsonData);
                setAnswer(jsonData);
                props.setCurrentAnswer(jsonData);// Assurez-vous d'accéder correctement à la propriété 'answer' de jsonData
                console.log('answer =>', jsonData.answer); // Utilisez jsonData.answer ici car setAnswer ne mettra à jour 'answer' que lors du prochain cycle de rendu
            })
            .catch(err => {
                setError(err.message);
                console.error('Error fetching the data:', err);
            });
    }, [props.questionId, props.beneficiaryId]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }
    if (isLoading) {
        return <div ><CircularProgress/></div>;
    }

    let informationsList = [];
    try {
        const informationsObj = JSON.parse(props.item.informations);
        if (informationsObj && Array.isArray(informationsObj.i)) {
            informationsList = informationsObj.i;
        }
    } catch (error) {
        console.error("Parsing error on informations:", error);
    }
    const flipCard = () => {
        setIsFlipped(!isFlipped);
    };


    // Fonction pour gérer le clic sur le bouton : "détruit" l'élément en le rendant invisible
    const detruireElement = () => {
        setVisible(false); // Change l'état pour rendre l'élément invisible
    };
    return (
        <div>
            {visible && (


        <body className="flex items-center justify-center h-screen w-full bg-transparent">
        <div className="card  mx-auto text-white h-screen w-full">
            <div className="flex justify-between items-center mb-6">
                <div className="text-3xl font-bold">{props.item.category
                }</div>
                <div className="text-xl font-bold">{props.item.subcategory
                }</div>
                <div className="info-icon text-2xl" onClick={flipCard}>i</div>
            </div>
            <div className="flex mb-4">

                <div className="mr-4 flex-shrink-0">
                    <span className="block w-10 h-10 rounded-full bg-yellow-400 text-xl text-center leading-10">{props.item.order}</span>

                </div>
                <div className="flex-grow">
                    <p>{props.item.question}</p>
                </div>
            </div>
            <div>
  <textarea
      className="w-full h-32 p-4 bg-white text-gray-800 rounded-lg focus:outline-none"
      value={answer}
      onChange={handleAnswerChange}
  />

            </div>
            <div className="flex justify-end">
                <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full"
                    onClick={() => handleSave(props.onBack)}>Précédent
                </button>
                <button
                    className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded-full"
                    onClick={() => handleSave(props.onContinue)}>Continuer
                </button>


            </div>
        </div>
        {/*<div className="card w-2/5 mx-auto text-white h-screen ">*/}
        {/*    <img src={Img}/>*/}
        {/*</div>*/}
        <div className={`card mx-auto text-white h-screen w-full ${isFlipped ? 'flipped' : ''}`}>
            <div className={`${isFlipped ? 'hidden' : 'block'}`}>
                <img src={Img}/>
            </div>
            <div className={`${isFlipped ? 'card-back block' : 'hidden'}`}>
                <ul>
                    {informationsList.map((info, index) => (
                        <li key={index}>{info}</li>
                    ))}
                </ul>
            </div>
        </div>
        </body>)}
        </div>
    );
};

export default QuestionCard;
