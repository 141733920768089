import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Formulaire from "./Formulaire";
import QuestionsTable from "./QuestionsTable";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import {AuthProvider} from "./AuthContext"; // Assurez-vous d'importer le composant Login

function App() {
    return (
        <Router>
            <AuthProvider>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/formulaire/:beneficiaryId" element={<PrivateRoute><Formulaire /></PrivateRoute>} />
                <Route path="/pdf" element={<PrivateRoute><QuestionsTable /></PrivateRoute>} />
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
                </AuthProvider>
        </Router>
    );
}

export default App;
