// AddBeneficiary.js
import React, {useEffect, useState} from 'react';
import { Box, Button, TextField, Typography, CircularProgress, Snackbar, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import Alert from '@mui/material/Alert';
import {useNavigate} from "react-router-dom";

const AddBeneficiary = ({ onClose }) => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        birthDate: '',
        diagnosis: '',
        // vigilanceState: '',
        school: '',
        sex: '',
    });
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [schools, setSchools] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    useEffect(() => {
        const fetchSchools = async () => {
            const token = localStorage.getItem('authToken');
            try {
                const response = await fetch(apiUrl+'schools',{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch schools');
                }
                const data = await response.json();
                setSchools(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des écoles :", error);
            }
        };

        fetchSchools();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(apiUrl+'beneficiaries', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    birth_date: formData.birthDate,
                    illnesses: formData.diagnosis,
                    // vigilance_state: formData.vigilanceState,
                    school: formData.school,
                    sex: formData.sex,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                setSnackbarMessage('Beneficiary added successfully!');
                setOpenSnackbar(true);
                onClose();
            } else {
                throw new Error(data.message || 'An error occurred');
            }
        }catch (error) {
            console.error("Erreur lors de la soumission :", error);
            setSnackbarMessage("An error occurred: " + error.message);
            setOpenSnackbar(true);
        }finally {
            setLoading(false);
        }
    };

    return (
        <Box p={3}>
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <Typography variant="h6" component="h2">Ajouter un nouveau bénéficiaire</Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Prénom"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Nom"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Date de naissance"
                            name="birthDate"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={formData.birthDate}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Diagnostic(s)"
                            name="diagnosis"
                            value={formData.diagnosis}
                            onChange={handleChange}
                        />
                        {/*<TextField*/}
                        {/*    fullWidth*/}
                        {/*    margin="normal"*/}
                        {/*    label="État de vigilance"*/}
                        {/*    name="vigilanceState"*/}
                        {/*    value={formData.vigilanceState}*/}
                        {/*    onChange={handleChange}*/}
                        {/*/>*/}
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Établissement scolaire</InputLabel>
                            <Select
                                label="Établissement scolaire"
                                name="school"
                                value={formData.school}
                                onChange={handleChange}
                            >
                                {schools.map((school) => (
                                    <MenuItem key={school.id} value={school.name}>
                                        {school.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <InputLabel>Sexe</InputLabel>
                            <Select
                                label="Sexe"
                                name="sex"
                                value={formData.sex}
                                onChange={handleChange}
                            >
                                <MenuItem value="M">Masculin</MenuItem>
                                <MenuItem value="F">Féminin</MenuItem>
                                <MenuItem value="X">Non spécifié</MenuItem>
                            </Select>
                        </FormControl>
                        <Button type="submit" variant="contained" sx={{ mt: 2 }}>Soumettre</Button>
                    </form>
                </>
            )}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AddBeneficiary;
