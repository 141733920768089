import React, {useState} from 'react';
import './questionCard.scss'; // Assurez-vous que le chemin d'accès est correct




const Stepper = ({ categoriesWithSubcategories, currentQuestion, onCategorySelect, onSubCategorySelect }) => {
    return (
        <ol className="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400 m-10">
            {categoriesWithSubcategories.map((categoryData, index) => (
                <li key={index} className="mb-10 ms-6"
                    style={{cursor: 'pointer'}}>
                    <span className={`absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-4 ring-white ${categoryData.category === currentQuestion.category ? 'bg-green-200' : 'bg-gray-100 dark:bg-gray-700'}`}
                          onClick={() => onCategorySelect(categoryData.category)}
                          >
                        {/* Icône de catégorie, verte si actuelle */}
                    </span>
                    <h3 className="font-medium leading-tight" onClick={() => onCategorySelect(categoryData.category)}>{categoryData.category}</h3>
                    {categoryData.category === currentQuestion.category && (
                        <ul className="text-sm">
                            {categoryData.subCategories.map((subCategory, subIndex) => (
                                <li key={subIndex}
                                    className={subCategory === currentQuestion.subcategory ? "text-white underline" : ""}
                                    onClick={() => onSubCategorySelect(subCategory)}
                                    style={{cursor: 'pointer'}}>
                                    {subCategory}
                                    {subCategory === currentQuestion.subcategory ? (
                                        <span className="text-yellow-400 ml-2">⭐</span>
                                    ) : null}
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ol>
    );
};

export default Stepper;
