import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Title from './Title';
import QuestionsTable from "./QuestionsTable";

function preventDefault(event) {
    event.preventDefault();
}

export default function Deposits() {
    const [beneficiary, setBeneficiary] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    // useEffect(() => {
    //     const fetchBeneficiary = async () => {
    //         try {
    //             const response = await fetch(apiUrl+`beneficiaries/6`);
    //             if (!response.ok) {
    //                 throw new Error('Could not fetch beneficiary');
    //             }
    //             const data = await response.json();
    //             setBeneficiary(data);
    //         } catch (error) {
    //             console.error('Error:', error);
    //         }
    //     };
    //
    //     fetchBeneficiary();
    // }, []);

    // if (!beneficiary) {
    //     return <div>Loading...</div>;
    // }

    // return (
    //     <React.Fragment>
    //         <Title>Questionnaire récent</Title>
    //         <Typography component="p" variant="h4">
    //             {beneficiary.first_name} {beneficiary.last_name}
    //         </Typography>
    //         <Typography color="text.secondary" sx={{ flex: 1 }}>
    //             le 31 Février 2024
    //         </Typography>
    //         <div>
    //
    //             <Link to={`/formulaire/${beneficiary.id}`}>
    //                 Voir questionnaire
    //             </Link>
    //             <Link to={`/pdf`}>
    //                pdf
    //             </Link>
    //
    //         </div>
    //     </React.Fragment>
    // );
    return (
        <React.Fragment>
            <Title>Bienvenue</Title>
            <Typography component="p" variant="h4">
                {/*NAME*/}
            </Typography>
            {/*<Typography color="text.secondary" sx={{ flex: 1 }}>*/}
            {/*    le 31 Février 2024*/}
            {/*</Typography>*/}
            <div>

                {/*<Link to={`/formulaire/${beneficiary.id}`}>*/}
                {/*    Voir questionnaire*/}
                {/*</Link>*/}
                {/*<Link to={`/pdf`}>*/}
                {/*    pdf*/}
                {/*</Link>*/}

            </div>
        </React.Fragment>
    )
    ;
}
